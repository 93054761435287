body {
  /*margin: 0;*/
  /*font-family: "Roboto Condensed", sans-serif;*/
  font-family: "Quicksand", "Open Sans", "Helvetica Neue", Arial, sans-serif;
  /*font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;*/
  /*color: #31383a; */
  /*  text-align: left; */
  /*  background-color: #f3f3f3; */
}

/*
.navbar {
    background-color: #e4e4e1; 
}

.navbar-light .navbar-nav .navbar-brand a {
  color: #33598a;
  font-weight: 700;
  font-size: 22px;
  line-height: 20px;
  letter-spacing: 0.02em;
  padding-right: 0.7rem;
  padding-left: 0.7rem;
}
*/
.navbar-nav {
  margin: auto;
}

.nav-link {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.05rem;
}

h1 {
  /* font-weight: bold; */
  /*   font-size: 2.8rem;
 */
  letter-spacing: 0.2rem;
  text-align: center;
  margin-bottom: 1.7rem;
  margin-top: 4rem;
  font-size: 2.2rem;
  /*   text-transform: uppercase;
 */
  color: #565655;
}

h2 {
  /*   font-weight: normal;
  font-size: 2.3rem; */
  letter-spacing: 0.1rem;
  margin-bottom: 1.2rem;
  /*   text-transform: uppercase; */
  margin-top: 2rem;
  color: #565655;
}

h3 {
  /*   font-weight: normal;
  font-size: 1.6rem; */
  /* letter-spacing: 0.1rem; */
  margin-bottom: 0.7rem;
  margin-top: 1.7rem;
  color: #565655;
}

h4 {
  /*   font-weight: normal;
  font-size: 1.6rem; */
  /* letter-spacing: 0.1rem; */
  margin-bottom: 0.3rem;
  margin-top: 1.5rem;
  color: #565655;
}

h5 {
  /*   font-weight: normal;
  font-size: 1.6rem; */
  /* letter-spacing: 0.1rem; */
  margin-bottom: 0.2rem;
  margin-top: 1.3rem;
  color: #565655;
}

html {
  overflow-y: scroll;
}

/* Trick footer to stay in the bottom */
body {
  position: relative;
  min-height: 100vh;
  margin: 0;
}

body::after {
  content: "";
  display: block;
  height: 80px; /* Set same as footer's height */
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
}

.hr-short {
  width: 40%;
}

.page {
  max-width: 1000px;
}

p {
  margin-bottom: 0.5rem;
}

ol,
ul,
dl {
  margin-bottom: 0.5rem;
}

.album-title {
  font-size: 1.1rem;
  margin-top: 0.5rem;
  margin-bottom: 1.25rem;
  color: #565655;
}

.card {
  border: 0;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}

.card-body {
  padding: 0.3rem;
}
